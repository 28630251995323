import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';

import { primaryColor } from 'theme-loader/theme/colors';

const ButtonSx = (props) => {
  const {
    buttonText,
    onButtonPress,
    sx,
    variant,
    isLoading,
    type,
    customColor,
    disabled,
    name,
    onMouseDown,
    startIcon,
  } = props;

  return (
    <LoadingButton
      loading={isLoading}
      disabled={disabled || isLoading}
      variant={variant === 'light-bg' ? 'outlined' : 'contained'}
      onClick={onButtonPress}
      onMouseDown={onMouseDown}
      type={type}
      startIcon={startIcon}
      sx={{
        'py': 0.5,
        'px': 5,
        'borderTopLeftRadius': '12px',
        'borderBottomLeftRadius': 0,
        'borderTopRightRadius': 0,
        'borderBottomRightRadius': '12px',
        'fontSize': '18px',
        'backgroundColor': variant === 'light-bg' ? 'white' : primaryColor[900],
        'borderColor': customColor ? customColor : primaryColor[900],
        'borderWidth': 1,
        'borderStyle': 'solid',
        '&:hover': {
          border: customColor
            ? `1px solid ${customColor}`
            : `1px solid ${primaryColor[900]}`,
        },
        'color':
          variant === 'light-bg'
            ? customColor
              ? customColor
              : primaryColor[900]
            : 'white',
        '&.Mui-disabled': {
          backgroundColor:
            variant === 'light-bg'
              ? customColor
                ? 'transparent'
                : primaryColor[500]
              : primaryColor[500],
        },
        ...sx,
      }}
      name={name}
    >
      {buttonText}
    </LoadingButton>
  );
};

ButtonSx.propTypes = {
  buttonText: PropTypes.string,
  variant: PropTypes.string,
  onButtonPress: PropTypes.func,
  sx: PropTypes.object,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  customColor: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onMouseDown: PropTypes.func,
  startIcon: PropTypes.object,
};

export default ButtonSx;
