import React from 'react';

import {
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import placeHolderImage from 'assets/img/placeholder.jpg';
import { useGetAllMaterialsQuery } from 'store/apis/order';
import { queryParamsBuilder } from 'utils/commonFunctions';

import FilterTree from './FilterTree';

const SelectMaterial = () => {
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');

  const navigate = useNavigate();
  const { materialType } = useParams();

  const [matData, setMatData] = React.useState([]);
  const [filteredMat, setFilteredMat] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [prevData, setPrevData] = React.useState([]);

  const { data, isLoading, isFetching } = useGetAllMaterialsQuery();

  const handleFilterMatSearch = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    if (!searchText.trim()) {
      setFilteredMat(prevData);
      return;
    }

    const regex = new RegExp(searchText, 'i'); // Case-insensitive regex for matching
    const searchResult = filteredMat?.reduce((acc, item) => {
      const obj = { ...item };

      if (item?.children) {
        obj.children = item.children.filter((subItem) =>
          regex.test(subItem?.label)
        );
        if (obj.children.length > 0) {
          acc.push(obj);
        }
      } else if (regex.test(item?.label)) {
        acc.push(obj);
      }

      return acc;
    }, []);

    setFilteredMat(searchResult);
  };

  React.useEffect(() => {
    if (data && !isLoading && !isFetching) {
      const caseType = materialType?.split(' ')?.[0];
      const materialsKey = `${caseType?.toLowerCase()}Materials`;

      if (caseType === 'Door') {
        const groupedData = data?.[materialsKey]?.reduce((acc, item) => {
          let parentName = 'Misc Doors';
          if (/Melamine\b/.test(item.name)) {
            parentName = 'Melamine/TFL Doors';
          } else if (/RTF\b/.test(item.name)) {
            parentName = 'Thermofoil/RTF Doors';
          } else if (/Standard\b/.test(item.name)) {
            parentName = 'Painted or Stained Wood Doors - Standard Colors';
          } else if (/Custom\b/.test(item.name)) {
            parentName = 'Painted or Stained Wood Doors - Custom Colors';
          }

          const existingGroup = acc.find((group) => group.label === parentName);
          if (existingGroup) {
            existingGroup.children.push({
              id: item.id,
              label: item.name,
            });
          } else {
            acc.push({
              id: parentName,
              label: parentName,
              children: [
                {
                  id: item.id,
                  label: item.name,
                },
              ],
            });
          }

          return acc;
        }, []);

        setMatData(groupedData);
      } else {
        setMatData(
          data?.[materialsKey]?.map((item) => ({
            id: item.id,
            label: item.name,
          }))
        );
      }
    }
  }, [data, isFetching, isLoading, materialType]);
  return (
    <Stack
      alignItems={'center'}
      sx={{
        p: 3,
        height: { xs: '100%', md: '100vh' },
        overflow: 'hidden',
      }}
    >
      <Stack
        gap={4}
        sx={{
          flexDirection: { xs: 'column', lg: 'row' },
          width: '100%',
          height: '100%',
        }}
      >
        <Stack
          gap={2}
          sx={{
            width: '100%',
            flex: 1,
            height: '100%',
            position: 'relative',
          }}
        >
          <Typography sx={{ fontWeight: 600, fontSize: 36 }}>
            FILTERS
          </Typography>
          <Box
            sx={{
              bgcolor: '#EEEEEE',
              pr: 3,
              py: 1,
              height: 'calc(100% - 50px)', // Adjust for title height
              position: 'sticky',
              top: 0, // Sticks to the top
              overflowY: 'auto', // Makes the Filter Tree scrollable if needed
            }}
          >
            <FilterTree
              matData={matData}
              setFilteredMat={setFilteredMat}
              setPrevData={setPrevData}
            />
          </Box>
        </Stack>
        <Stack
          gap={2}
          sx={{
            'flex': 3,
            'height': '100%',
            'overflowY': 'auto',
            '&::-webkit-scrollbar': {
              width: 0,
              background: 'transparent',
            },
            'msOverflowStyle': 'none',
            'scrollbarWidth': 'none',
          }}
        >
          <Typography
            sx={{ textAlign: 'center', fontWeight: 600, fontSize: 36 }}
          >
            {materialType}
          </Typography>
          <Divider />
          <Stack direction={'row'} sx={{ pl: 2 }}>
            <Typography>
              Showing{' '}
              {materialType !== 'Door Material'
                ? filteredMat?.length
                : filteredMat?.reduce((acc, mat) => {
                    acc += mat.children?.length;
                    return acc;
                  }, 0)}{' '}
              materials
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={1} sx={{ pl: 2 }}>
            <TextField
              onChange={handleFilterMatSearch}
              value={searchText}
              placeholder="Search material Name"
              id="outlined-basic"
              variant="outlined"
              sx={{
                '& .MuiInputBase-root': {
                  height: 50,
                  backgroundColor: 'white',
                  maxWidth: '300px',
                  width: '300px',
                },
              }}
            />
          </Stack>
          <Grid
            container
            spacing={2}
            sx={{
              ...(materialType === 'Door Material' && { ml: 1 }),
              p: 2,
            }}
          >
            {!filteredMat?.length && (
              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Typography>No materials found</Typography>
              </Stack>
            )}
            {filteredMat?.map((mat, index) => {
              if (mat?.children) {
                return (
                  <Box key={mat?.id} sx={{ width: '100%' }}>
                    {index !== 0 && <Divider sx={{ width: '100%', my: 2 }} />}
                    <Typography
                      sx={{ fontWeight: 'bold', my: 2, fontSize: '20px' }}
                    >
                      {mat?.label}
                    </Typography>
                    <Grid container spacing={2}>
                      {mat?.children?.map((item) => (
                        <Grid key={item?.id} item xs={4} lg={2} sx={{ p: 1 }}>
                          <img
                            onClick={() => {
                              navigate(
                                `/select-material/${materialType}/${item?.id}${
                                  from ? queryParamsBuilder({ from }) : ''
                                }`
                              );
                            }}
                            src={placeHolderImage}
                            alt="logo"
                            width={'100%'}
                            style={{
                              height: 'auto',
                              objectFit: 'contain',
                              border: '1px solid #EEEEEE',
                              cursor: 'pointer',
                            }}
                          />
                          <Tooltip title={item?.label} placement="top" arrow>
                            <Typography
                              noWrap
                              sx={{
                                maxWidth: '200px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {item?.label}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                );
              }
              return (
                <Grid key={mat?.id} item xs={4} lg={2}>
                  <img
                    onClick={() => {
                      navigate(
                        `/select-material/${materialType}/${mat?.id}${
                          from ? queryParamsBuilder({ from }) : ''
                        }`
                      );
                    }}
                    src={placeHolderImage}
                    alt="logo"
                    width={'100%'}
                    style={{
                      height: 'auto',
                      objectFit: 'contain',
                      border: '1px solid #EEEEEE',
                      cursor: 'pointer',
                    }}
                  />
                  <Tooltip title={mat?.label} placement="top" arrow>
                    <Typography
                      noWrap
                      sx={{
                        maxWidth: '180px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontSize: '13px',
                      }}
                    >
                      {mat?.label}
                    </Typography>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectMaterial;
