import React, { useEffect, useState } from 'react';

import { Box, Breadcrumbs, Divider, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import placeHolderImage from 'assets/img/placeholder.jpg';
import AlertPopup from 'components/alert-popup/AlertPopup';
import ButtonSx from 'components/button/ButtonSx';
import StickyFooter from 'components/sticky-footer/StickyFooter';
import { useSetDefaultOptionsMutation } from 'store/apis/customer';
import {
  useGetAllMaterialsQuery,
  useUpdateAllCartMutation,
} from 'store/apis/order';
import { useNotifyToast } from 'utils/useNotifyToast';

const Material = () => {
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');
  const navigate = useNavigate();
  const params = useParams();
  const breadcrumbPaths = Object.values(params);
  const { materialType, materialId } = useParams();
  const [currentMat, setCurrentMat] = useState({});
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: 'linear',
  };
  const caseType = materialType?.split(' ')?.[0];
  const materialsKey = `${caseType?.toLowerCase()}Materials`;
  const { data, isLoading, isFetching } = useGetAllMaterialsQuery();

  const selectedProjectDetails = useSelector(
    (state) => state.project.selectedProjectForOptions
  );

  useEffect(() => {
    if (data && !isLoading && !isFetching) {
      const mat = data?.[materialsKey]?.find((item) => item.id === materialId);
      setCurrentMat(mat);
    }
  }, [data, isFetching, isLoading, materialId, materialsKey]);

  const handleBreadcrumbClick = () => {
    navigate(`/select-material/${materialType}`);
  };

  const [updateAllCart] = useUpdateAllCartMutation();

  const [
    setDefaultOptions,
    {
      data: setDefaultOptionData,
      isLoading: isSetDefaultOptionsLoading,
      isSuccess,
      isError,
      error,
    },
  ] = useSetDefaultOptionsMutation();

  const handleMatSelection = () => {
    const payload = {};
    if (from) {
      payload.defaultOptions = true;
    } else {
      payload.materialOptions = true;
      payload.projectId = selectedProjectDetails?.id;
    }
    if (currentMat?.usage === 'Door') {
      payload.doorMaterial = currentMat?.name;
    } else if (currentMat?.usage === 'Case') {
      payload.caseMaterial = currentMat?.name;
    } else {
      payload.drawers = currentMat?.name;
    }
    setDefaultOptions(payload);
    if (!from) {
      updateAllCart(payload);
    }
    setIsAlertOpen(false);
  };

  const notifyToast = useNotifyToast();
  useEffect(() => {
    if (!isSetDefaultOptionsLoading && isSuccess) {
      if (from) {
        navigate('/my-account/default-product-options');
      } else {
        navigate('/projects/material');
      }
      notifyToast(
        setDefaultOptionData?.message || 'Material saved successfully',
        'defaultOptions',
        'success'
      );
    }
    if (isError) {
      notifyToast(error?.data?.errorMessage, 'defaultOptions', 'error');
    }
  }, [
    error?.data?.errorMessage,
    from,
    isError,
    isSetDefaultOptionsLoading,
    isSuccess,
    navigate,
    notifyToast,
    setDefaultOptionData?.message,
  ]);

  return (
    <>
      <Stack alignItems={'center'} sx={{ p: 3 }}>
        <Stack
          gap={3}
          width={'100%'}
          sx={{
            flexDirection: { xs: 'column', lg: 'row' },
            justifyContent: { xs: 'normal', lg: 'center' },
            alignItems: { xs: 'center', lg: 'normal' },
          }}
        >
          <Stack
            gap={2}
            sx={{
              width: 'auto',
              maxWidth: { xs: '100%', sm: '80%', md: '60%' },
              padding: { xs: 1, sm: 2 },
            }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbPaths?.splice(1, 3)?.map((crumb, index) => {
                const isLast = crumb === Object.values(params)?.at(-1);

                return isLast ? (
                  <Typography
                    sx={{ fontSize: { xs: '14px', sm: '15px' } }}
                    key={index}
                    color="text.primary"
                  >
                    {currentMat?.name}
                  </Typography>
                ) : (
                  <Typography
                    key={index}
                    color="inherit"
                    onClick={() => handleBreadcrumbClick()}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontSize: { xs: '14px', sm: '15px' },
                    }}
                  >
                    {crumb}
                  </Typography>
                );
              })}
            </Breadcrumbs>
            <img
              src={placeHolderImage}
              alt="logo"
              width={'auto'}
              style={{
                height: 'auto',
                objectFit: 'contain',
                border: '1px solid #EEEEEE',
                cursor: 'pointer',
              }}
            />
            <Box
              maxWidth={'500px'}
              sx={{ ml: 2, display: { xs: 'none', sm: 'block' } }}
            >
              <Slider {...settings}>
                {[1, 2, 3, 4, 5, 6, 7]?.map((item) => (
                  <div key={item}>
                    <img
                      src={placeHolderImage}
                      alt="logo"
                      width={'100px'}
                      style={{
                        height: 'auto',
                        objectFit: 'contain',
                        border: '1px solid #EEEEEE',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </Box>
          </Stack>
          <Stack maxWidth={'700px'} width={'100%'} gap={2}>
            <Typography
              sx={{
                textAlign: 'start',
                fontWeight: 600,
                fontSize: { xs: 26, md: 36 },
              }}
            >
              {currentMat?.name}
            </Typography>
            <Divider />

            <Typography
              sx={{ textAlign: 'start', fontSize: { xs: 20, md: 26 } }}
            >
              {currentMat?.doorMessage}
              {currentMat?.caseMessage}
              {currentMat?.drawerMessage}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <StickyFooter>
        <Stack
          sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
          gap={'15px'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <ButtonSx
            disabled={
              from ? false : !Object.keys(selectedProjectDetails || {})?.length
            }
            sx={{
              ...(from !== 'project' && { fontWeight: 700 }),
              width: {
                xs: '100%',
                sm: from !== 'project' ? 400 : 'auto',
              },
            }}
            buttonText="Select this material"
            variant={'light-bg'}
            onButtonPress={() => {
              setIsAlertOpen(true);
            }}
            isLoading={isSetDefaultOptionsLoading}
          />
        </Stack>
      </StickyFooter>

      {isAlertOpen && (
        <AlertPopup
          title={'Alert'}
          submitButtonText="Set"
          isOpen={isAlertOpen}
          handleClose={() => {
            setIsAlertOpen(false);
          }}
          isConfirmLoading={isSetDefaultOptionsLoading}
          onConfirm={handleMatSelection}
        >
          <Box px={3}>
            {!from ? (
              <Typography
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                This action will set the <strong>{materialType}</strong> for
                project <strong>{selectedProjectDetails?.name}</strong> to{' '}
                <strong>{currentMat?.name}</strong>. Are you sure you want to
                set this material?
              </Typography>
            ) : (
              <Typography
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                This action will set the <strong>{materialType}</strong> for
                <strong> default product options</strong> to{' '}
                <strong>{currentMat?.name}</strong>. Are you sure you want to
                set this material?
              </Typography>
            )}
          </Box>
        </AlertPopup>
      )}
    </>
  );
};

export default Material;
