import * as React from 'react';

import { Button, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import PropTypes from 'prop-types';

function getItemDescendantsIds(item) {
  const ids = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemDescendantsIds(child));
  });

  return ids;
}

export default function FilterTree({ matData, setFilteredMat, setPrevData }) {
  const [selectedItems, setSelectedItems] = React.useState([]);
  const toggledItemRef = React.useRef({});
  const apiRef = useTreeViewApiRef();

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  React.useEffect(() => {
    // Initialize the filtered materials with all items selected initially
    setFilteredMat(matData);
    setPrevData(matData);
    setSelectedItems(
      matData?.reduce((acc, mat) => {
        if (mat.children) {
          acc.push(...mat.children.map((child) => child.id));
        }
        acc.push(mat?.id);
        return acc;
      }, [])
    );
  }, [matData, setFilteredMat, setPrevData]);
  const handleSelectedItemsChange = (event, newSelectedItems) => {
    const currentToggleItemId = Object.keys(toggledItemRef.current)[0];
    const parentItems = matData?.map((item) => item.label);
    const getParent = matData?.find((item) =>
      item?.children
        ?.map((subItem) => subItem.id)
        ?.includes(currentToggleItemId)
    );

    // when all children are unchecked, remove parent from the selected Items
    const isAllChildChecked = getParent?.children
      ?.map((child) => child.id)
      ?.every((id) => !newSelectedItems.includes(id));

    let arr = newSelectedItems;

    if (isAllChildChecked) {
      arr = newSelectedItems?.filter((item) => item !== getParent?.label);
    } else if (!newSelectedItems?.includes(getParent?.label)) {
      arr.push(getParent?.label);
    }

    setSelectedItems(arr);

    const preparedMatData = matData?.reduce((acc, item) => {
      const obj = { ...item };
      if (item?.children) {
        if (parentItems?.includes(currentToggleItemId)) {
          obj.children = item?.children;
        } else {
          obj.children = item?.children?.filter((subItem) =>
            arr.includes(subItem.id)
          );
        }

        acc.push(obj);
      } else {
        acc.push(obj);
      }

      return acc;
    }, []);

    const filteredMat = preparedMatData.filter((item) =>
      arr?.includes(item.id)
    );
    setFilteredMat(filteredMat);
    setPrevData(filteredMat);

    const itemsToSelect = [];
    const itemsToUnSelect = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...arr, ...itemsToSelect].filter((itemId) => !itemsToUnSelect[itemId])
      )
    );

    setSelectedItems(newSelectedItemsWithChildren);

    toggledItemRef.current = {};
  };
  const handleSelectClick = () => {
    if (selectedItems.length === 0) {
      const allItemIds = matData.reduce((acc, item) => {
        acc.push(item.id);
        if (item.children) {
          acc.push(...item.children.map((child) => child.id));
        }
        return acc;
      }, []);

      setSelectedItems(allItemIds);
      setFilteredMat(matData);
      setPrevData(matData);
    } else {
      setSelectedItems([]);
      setFilteredMat([]);
      setPrevData([]);
    }
  };

  return (
    <Box
      sx={{
        'minHeight': 352,
        'minWidth': 290,
        '& .Mui-selected': {
          backgroundColor: 'transparent !important', // Removes the background
        },
      }}
    >
      <Stack alignItems={'end'}>
        <Button onClick={handleSelectClick}>
          <Typography>
            {selectedItems.length === 0 ? 'Select all' : 'Deselect all'}
          </Typography>
        </Button>
      </Stack>
      <RichTreeView
        multiSelect
        checkboxSelection
        apiRef={apiRef}
        items={matData}
        selectedItems={selectedItems}
        onSelectedItemsChange={handleSelectedItemsChange}
        onItemSelectionToggle={handleItemSelectionToggle}
        sx={{
          '.MuiTreeItem-content': {
            display: 'flex',
            alignItems: 'flex-start',
          },
        }}
      />
    </Box>
  );
}

FilterTree.propTypes = {
  matData: PropTypes.array,
  setFilteredMat: PropTypes.func,
  setPrevData: PropTypes.func,
};
