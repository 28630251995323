import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

import { pxToRem } from 'theme-loader/theme/typography';

const AlertPopup = (props) => {
  const {
    children,
    onConfirm,
    isOpen,
    handleClose,
    title,
    description,
    cancelButtonText,
    submitButtonText,
    isConfirmLoading,
    isConfirmDisabled,
    paperStyles,
  } = props;
  return (
    <Dialog
      open={isOpen || false}
      onClose={() => {
        if (!isConfirmLoading) {
          handleClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ boxShadow: 'none' }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0,0,0,0.5)',
        },
      }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          ...paperStyles,
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ overflowY: 'unset' }}>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      {children}
      <DialogActions>
        <Button
          onClick={() => {
            if (!isConfirmLoading) {
              handleClose();
            }
          }}
          sx={{ fontSize: pxToRem(17), mr: 2 }}
          color="error"
        >
          {cancelButtonText || 'Cancel'}
        </Button>
        <LoadingButton
          loading={isConfirmLoading}
          disabled={isConfirmLoading || isConfirmDisabled}
          onClick={() => onConfirm()}
          autoFocus
          sx={{ fontSize: pxToRem(17), mr: 1 }}
        >
          {submitButtonText || 'Login'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

AlertPopup.propTypes = {
  cancelButtonText: PropTypes.string,
  children: PropTypes.element,
  description: PropTypes.string,
  handleClose: PropTypes.func,
  isConfirmDisabled: PropTypes.bool,
  isConfirmLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  paperStyles: PropTypes.any,
  submitButtonText: PropTypes.string,
  title: PropTypes.string,
};

export default AlertPopup;
